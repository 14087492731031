import Vue from "vue";
import NotFound from "./pages/NotFound.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

import "./assets/fonts/ReadexPro/style.css";
Vue.config.productionTip = false;

new Vue({
  i18n,
  vuetify,
  render: (h) => h(NotFound),
}).$mount("#app");
